
  import axios, { AxiosError } from 'axios';
  import { defineComponent, ref, onMounted, watch, onBeforeMount } from 'vue';
  import useAlert from "@/composables/Alert";
  import {
    CampHeader,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampEmptyListFeedbackV2,
    CampFooterPaginationControl
  } from '@/components';
  import { useLoaderStore } from "@/store/LoaderStore";
  import { useFilterMainStore } from '@/store/FilterMainStore';
  import { useCompanyStore } from '@/store/CompanyStore';
  import { useStoreStore } from '@/store/StoreStore';
  import { useSaleStore } from '@/store/SaleStore';
  // import ModalExcellURLList from "./Components/ModalExcellURLList.vue";
  import moment from 'moment';
  import { campHandleCPF } from '@/composables/DataValidation';
  import { useSocketStore } from '@/store/SocketStore';
  import { useAuthStore } from '@/store/AuthStore';
  import { reactive } from 'vue';
  import { ptBr } from 'element-plus/es/locale';
  import ExcelJS from 'exceljs';
  import { campGoToTopByScrollMode } from '@/composables/Helpers';
  
  interface ISaleDataFetch {
    id: number;
    cpf: string;
    name: string;
    product: string;
    status: string | number;
    quantity: number;
    current_points: 2
    date: string
  }
  
  interface ISalePrint extends ISaleDataFetch {
    value: number;
  }
  
  interface ISaleResponse {
    data: {
      data: {
        from: number,
        last_page: number,
        data: ISaleDataFetch[]
        error: boolean,
        message: string,
        errorCode: string
      }
    }
  }
  
  export interface IExcellURL {
    url: string
  }

  const INIT_BODY_PARAMS: {
    id_company: undefined;
    id_store: undefined;
    status: undefined;
    quantity: never[];
    sku: undefined;
    role: undefined;
    start_date: undefined;
    end_date: undefined;
    latest?: boolean | number;
    expires_at?: boolean | number;
    page?: number;
    competitor: any;
  } = {
    id_company: undefined,
    id_store: undefined,
    status: undefined,
    quantity: [],
    sku: undefined,
    role: undefined,
    start_date: undefined,
    end_date: undefined,
    latest: undefined,
    expires_at: undefined,
    page: 1,
    competitor: undefined,
  }
  
  export default defineComponent({
    name: "Report",
    components: {
      CampHeader,
      CampTable,
      CampTableTd,
      CampTableTh,
      // ModalExcellURLList,
      CampEmptyListFeedbackV2,
      CampFooterPaginationControl,
    },
    setup() {
      const { showTimeAlert } = useAlert()
      const loaderStore = useLoaderStore()
      const authStore = useAuthStore()
      // Company
      const companyStore = useCompanyStore()
      // Store
      const storeStore = useStoreStore()
      // Sale
      const areAllSelected = ref(false)
      const isLoading = ref(false)
      const saleStore = useSaleStore()
      const saleList = ref<ISalePrint[] | null>(null)
      const saleListRender = ref<any[]>([])
      const keywordSearch = ref("")
      const toggleModalExcellURLList = ref(false)
      const companies = ref<any>()
      const company = ref<any>()
      const stores = ref<any>()
      const store = ref<any>()
      const statusSale = ref<any>()
      const status = ref<any>()
      const quantity = ref([])
      const quantityOptions = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) 
      const date = ref<any>()
      const sku = ref<any>()
      const role = ref<any>(7)
      const start_date = ref<any>()
      const end_date = ref<any>()
      const latest = ref<undefined | boolean | number>()
      const expires_at = ref<undefined | boolean | number>()
      const pageID = ref(1)
      const pageTotal = ref(1)
      const bodyParams = ref<typeof INIT_BODY_PARAMS>(JSON.parse(JSON.stringify(INIT_BODY_PARAMS)))
      const competitors = ref<any>()
      const competitorId = ref<any>(7)


  
      // Functions
      /* Fetch */
      async function handleGetSaleList() {
        bodyParams.value = {
          id_company: company.value,
          id_store: store.value,
          status: status.value,
          quantity: quantity.value,
          sku: sku.value,
          role: role.value,
          start_date: date?.value?.length ? date?.value[0] : '',
          end_date: date?.value?.length ? date?.value[1] : '',
          latest: latest.value,
          expires_at: expires_at.value,
          competitor: competitorId.value,
        }
        getSaleList(1)
      }

      function handleResetFields() {
        company.value = bodyParams.value.id_company
        store.value = bodyParams.value.id_store
        status.value = bodyParams.value.status
        quantity.value = bodyParams.value.quantity
        sku.value = bodyParams.value.sku
        role.value = bodyParams.value.role
        date.value = [bodyParams.value.start_date, bodyParams.value.end_date]
        latest.value = bodyParams.value.latest
        expires_at.value = bodyParams.value.expires_at
        competitorId.value = bodyParams.value.competitor
      }
      async function getSaleList(page: number, isLoadingProp = true) {
        areAllSelected.value = false
        keywordSearch.value = ""
        isLoading.value = isLoadingProp
        pageID.value = page
        const url = `/api/getReportSales`
        const body: typeof INIT_BODY_PARAMS = JSON.parse(JSON.stringify(bodyParams.value))
        body.page = pageID.value
        try {
  
          // if(saleStore.getStartDate && saleStore.getEndDate) {
          //   params += `start_date=${moment(saleStore.getStartDate).format('DD-MM-YYYY')}&end_date=${moment(saleStore.getEndDate).format('DD-MM-YYYY')}&`
          // }
  
          const response: ISaleResponse = await axios.post(url, body)
          const { data, last_page  } = response.data.data
          pageTotal.value = last_page
          saleList.value = data.map(el => ({ ...el, value: el.quantity }))
          saleListRender.value = [...saleList.value]
        } catch (error) {
          if (error instanceof AxiosError) {
            const isLevel600 = (authStore?.getUser?.role?.level >= 600) ? true : false
            if (isLevel600 && error.response?.data.message == "Necessário estar com uma loja selecionada!")
              showTimeAlert(error.response?.data.message, "warning")
            if (error.response?.data.message != "Necessário estar com uma loja selecionada!")
              showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          loaderStore.close()
          isLoading.value = false
        }
        return false
      }
  
      async function getCompaniesList() {
        try {
          const response = await axios.get('/api/getCompany')
          companies.value = response.data.data
          // companies.value = companiesResponse.filter(el => el.id != companyStore.id)
        } catch (error) {
        }
      }

      async function getCompetitorAllowed() {
        try {
          const response = await axios.get('/api/getCompetitorAllowed')
          competitors.value = response.data.data
          // companies.value = companiesResponse.filter(el => el.id != companyStore.id)
        } catch (error) {
        }
      }
  
      async function getStoresList() {
        try {
          const response = await axios.get('/api/getStore')
          stores.value = response.data.data
          // stores.value = storesResponse.filter(el => el.id != companyStore.id)
        } catch (error) {
        }
      }
  
      async function getSaleStatus() {
        try {
          const response = await axios.get('/api/getSaleStatus')
          statusSale.value = response.data.data
          // stores.value = storesResponse.filter(el => el.id != companyStore.id)
        } catch (error) {
        }
      }
  
      async function generateExcel() {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Relatório');
  
        // Obtenha os dados da tabela
        const tableData: any = saleListRender.value
  
        // Adicione os cabeçalhos da tabela ao arquivo Excel
        const headers = Object.keys(tableData[0]);
        const headersTranslate = headers.map(header => handlerHeaderExcel(header))
        worksheet.addRow(headersTranslate);
  
        // Adicione os dados da tabela ao arquivo Excel
        tableData.forEach(row => {
          worksheet.addRow(Object.values(row));
        });
  
        // Gere o arquivo Excel e faça o download
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        const fileName = 'relatorio.xlsx';
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      }
  
      function handleFormatInMonentaryValue(value: number) {
        return value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      }
  
      function handlerHeaderExcel(header: any): String {
        if (header === 'qtd') return 'Quantidade'
        if (header === 'current_points') return 'Pontos atuais'
        if (header === 'total_points') return 'Pontos totais'
        if (header === 'date') return 'Data'
        if (header === 'name') return 'Nome'
        if (header === 'trainingName') return 'Nome do treinamento'
        if (header === 'moderator') return 'Moderador'
        if (header === 'company') return 'Companhia'
        if (header === 'store') return 'Loja'
        if (header === 'competitor') return 'Vendedor'
        if (header === 'value') return 'Valor'
        if (header === 'status') return 'Status'
        if (header === 'sku') return 'SKU'
        if (header === 'expires_at') return 'Expiração da venda'
        return header
      }

      function handleFilterObjectsByKeyword(): boolean {
      if(!saleList.value)
        return true
        saleListRender.value = saleList.value.filter((item) => {
        for (const key in item) {
          const propValue = item[key];
          if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase()))
            return true;
          if (typeof propValue === 'number' && keywordSearch.toString() === propValue.toString())
            return true;
        }
        return false;
      });
      return false
    }

    async function handlePaginationRendering(ID: number) {
      loaderStore.open()
      handleResetFields()
      await getSaleList(ID, false)
      campGoToTopByScrollMode(false)
    }
  
      onMounted(() => {
        getSaleList(1)
        getCompaniesList()
        getStoresList()
        getSaleStatus()
        getCompetitorAllowed()
      })
  
  
      watch(() => { companyStore.company, storeStore.store, saleStore.getStartDate, saleStore.getEndDate }, () => getSaleList(pageID.value), { deep: true })
  
      watch(() => areAllSelected.value, () => (''))
  
      watch(() => keywordSearch.value, () => (handleFilterObjectsByKeyword()))
  
      return {
        areAllSelected,
        isLoading,
        keywordSearch,
        companyStore,
        saleList,
        saleListRender,
        toggleModalExcellURLList,
        handleFormatInMonentaryValue,
        moment,
        saleStore,
        campHandleCPF,
        authStore,
        companies,
        company,
        stores,
        store,
        statusSale,
        status,
        getSaleList,
        quantity,
        ptBr,
        date,
        start_date,
        end_date,
        generateExcel,
        quantityOptions,
        sku,
        role,
        latest,
        pageID,
        pageTotal,
        handlePaginationRendering,
        handleGetSaleList,
        competitors,
        competitorId,
        expires_at
      }
    }
  })
  